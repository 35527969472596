import React, { Component } from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { NavLink } from 'react-router-dom';

class SimpleFooter extends Component {
  constructor() {
    super()

    this.state = {
      newEmail: ''
    }
  }

  subscribeUser = (e) => {
    e.preventDefault();

    let data = {
      email: this.state.newEmail
    }

    const link = "https://API.techfluent.io/mailing-subscription"

    fetch(link, { 
      body: JSON.stringify(data),
      mode: "no-cors",
      method: "POST"
    })
    .then(response => {
      this.showNotification('Haces parte de nuestro mailing list');
    })
    .catch(err => {
        console.log(err)
    });

    this.setState({
      newEmail: ''
    })
  }

  showNotification = (mensaje) => {
    NotificationManager.success(mensaje);
  }

  render() {
    return (
      <div className="App stretched" dataloader="11" dataloadercolor="#543456">
        <footer id="footer" className="dark noborder">
          <NotificationContainer/>

          <div className="container center">
            <div className="footer-widgets-wrap">
              <div className="row divcenter clearfix">
                <div className="col-lg-4">
                  <div className="widget clearfix">
                    <h4>Site Links</h4>
                    <ul className="list-unstyled footer-site-links nobottommargin">
                      <li><NavLink to="/" className="white-link" data-scrollto="#wrapper" data-easing="easeInOutExpo" data-speed="1250" data-offset="70">Home</NavLink></li>
                      <li><NavLink to="/calendario-cursos-programacion" className="white-link" data-scrollto="#section-about" data-easing="easeInOutExpo" data-speed="1250" data-offset="70">Clases</NavLink></li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="widget subscribe-widget clearfix" data-loader="button">
                    <h4>Suscribete</h4>
                    <div className="widget-subscribe-form-result"></div>
                    <form id="widget-subscribe-form" className="nobottommargin" onSubmit={(e) => this.subscribeUser(e)}>
                      <input onChange={e => this.setState({ newEmail: e.target.value})} value={this.state.newEmail} type="email" id="widget-subscribe-form-email" name="widget-subscribe-form-email" className="form-control form-control-lg not-dark required email" placeholder="Correo electronico"/>
                      <button disabled={this.state.newEmail === ''} className="button button-border button-circle button-light topmargin-sm" type="submit">Enviar</button>
                    </form>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="widget clearfix">
                    <h4>Contácto</h4>
                    <p className="lead">techfluentsocial@gmail.com<br/>Bogota, Colombia</p>
                    <div className="center topmargin-sm">
                      <a href="https://www.facebook.com/TechFluent-2259485724311907/" target="_blank" className="social-icon inline-block noborder si-small si-facebook" title="Facebook">
                        <i className="icon-facebook white-link"></i>
                        <i className="icon-facebook white-link"></i>
                      </a>
                      <a href="https://twitter.com/techfluentco" target="_blank" className="social-icon inline-block noborder si-small si-twitter" title="Twitter">
                        <i className="icon-twitter white-link"></i>
                        <i className="icon-twitter white-link"></i>
                      </a>
                      <a href="https://co.linkedin.com/in/techfluentco" target="_blank" className="social-icon inline-block noborder si-small si-linkedin" title="Linkedin">
                        <i className="icon-linkedin white-link"></i>
                        <i className="icon-linkedin white-link"></i>
                      </a>
                      <a href="https://www.instagram.com/techfluent.io/" target="_blank" className="social-icon inline-block noborder si-small si-instagram" title="Instagram">
                        <i className="icon-instagram white-link"></i>
                        <i className="icon-instagram white-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="copyrights">
            <div className="container center clearfix">
              Copyright TechFluent 2019 | All Rights Reserved
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default SimpleFooter;