import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class SingleEvent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { pic, day, month, title, badge, time, place, description, path, registro } = this.props.workshop;
    return (
      <div>
        <div className="entry clearfix">
          <div className="entry-image">
            <a href="#">
              <img src={pic} alt="curso de programacion calendario"/>
              <div className="entry-date">{day}<span>{month}</span></div>
            </a>
          </div>
          <div className="entry-c">
            <div className="entry-title">
              <h2><a href="#">{title}</a></h2>
            </div>
            <ul className="entry-meta clearfix">
              <li><span className="badge badge-warning">{badge}</span></li>
              <li><a href="#"><i className="icon-time"></i>{time}</a></li>
              <li><a href="#"><i className="icon-map-marker2"></i>{place}</a></li>
            </ul>
            <div className="entry-content">
              <p>{description}</p>
              <NavLink to={registro} className="btn btn-secondary" disabled="disabled">Inscribirse</NavLink> <NavLink to={path} className="btn btn-danger">Leer más</NavLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SingleEvent;
