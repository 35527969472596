import React, { Component } from 'react';
import './App.css';
import Main from './Main/Main';
import { Route, Switch } from 'react-router-dom';
import FrontEnd from './FrontEnd/FrontEnd';
import UX from './UX/UX';
import Workshops from './Workshops/Workshops';
import Articles from './Articles/Articles';
import NotFound from './NotFound/NotFound';
import Article1 from './Articles/Article1';
import RegistroFrontEnd from './RegistroFrontEnd/RegistroFrontEnd';
import RegistroUX from './RegistroUX/RegistroUX';
import Angular from './Angular/Angular';
import ReactJs from './React/React';
import RegistroReact from './RegistroReact/RegistroReact';
import RegistroAngular from './RegitstroAngular/RegistroAngular';
import Websites from './Websites/Websites';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path='/bootcamp-web-development' component={FrontEnd} />
          <Route exact path='/curso-ux' component={UX} />
          <Route exact path='/articulos-tecnologia' component={Articles} />
          <Route exact path='/calendario-cursos-programacion' component={Workshops} />
          <Route exact path='/' component={Main} />
          <Route exact path='/registro-front-end' component={RegistroFrontEnd} />
          <Route exact path='/curso-angular' component={Angular}/>
          <Route exact path='/curso-react' component={ReactJs}/>
          <Route exact path='/registro-ux' component={RegistroUX} />
          <Route exact path='/registro-curso-react' component={RegistroReact} />
          <Route exact path='/registro-curso-angular' component={RegistroAngular} />
          <Route exact path='/la-importancia-de-programacion' component={Article1}/>
          <Route exact path='/websites-y-aplicaciones' component={Websites}/>
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default App;
