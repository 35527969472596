import React, { Component } from 'react';
import SimpleFooter from '../SimpleFooter/SimpleFooter';
import WorkshopData from '../Data/WorkshopsData';
import SingleEvent from '../Single-Event/Single-Event';
import SimpleMenu from '../SimpleMenu/SimpleMenu';

class Workshops extends Component {

  componentDidMount() {
    document.body.scrollTop = 0;
  }

  getWorkshops = () => {
    const wdata = new WorkshopData(); 
    return wdata.futureWorkshops().map((workshop, index) => {
      return (
        <div key={workshop.title + index}>
          <SingleEvent workshop={workshop} />
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div id="wrapper" className="clearfix">
          <SimpleMenu />
          <section id="content">
            <div className="content-wrap">
              <div className="container clearfix">
                <div className="nobottommargin">
                  <div id="posts" className="events small-thumbs">
                    {this.getWorkshops()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <SimpleFooter />
      </div>
    );
  }
}

export default Workshops;
