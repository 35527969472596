import React, { Component } from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class Contactform extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nombre: '',
      email: '',
      telefono: '',
      asunto: '',
      mensaje: ''
    }
  }

  formSubmit = (e) => {
    e.preventDefault();
    let { nombre, email, telefono, asunto, mensaje } = this.state;
    let data = {
      nombre: nombre,
      email: email,
      telefono: telefono,
      asunto: asunto,
      mensaje: mensaje
    }

    const link = "https://API.techfluent.io/contact-us"

    fetch(link, { 
      body: JSON.stringify(data),
      mode: "no-cors",
      method: "POST"
    })
    .then(response => {
      this.showNotification('Mensaje enviado');
    })
    .catch(err => {
        console.log(err)
    });

    this.setState({
      nombre: '',
      email: '',
      telefono: '',
      asunto: '',
      mensaje: ''
    })
    document.querySelector('#template-contactform').reset();
  }

  showNotification = (mensaje) => {
    NotificationManager.success(mensaje);
  }

  showError = () => {
    NotificationManager.error('Error message', 'Click me!', 5000, () => {
      alert('callback');
    });
  }

  render() {
    const style = this.props.close ? { margin: '100px auto' } : null;
    return (
      <div className="container clearfix" style={style} id="contact">
        <NotificationContainer/>
        <h2 className="divcenter bottommargin font-body" style={{maxWidth: '700px', fontSize: '40px', fontWeight: '700'}}>Contáctanos</h2>
        <div className="divcenter topmargin" style={{maxwidth: '850px'}}>
          <div className="form-widget">
            <div className="form-result"></div>

            <form id="template-contactform" name="template-contactform" onSubmit={ (e) => this.formSubmit(e)} className="contact-form">
              <div className="form-process"></div>
              <div className="col_half contact-form-input">
                <input onChange={e => this.setState({ nombre: e.target.value})} required
                  type="text" id="template-contactform-name" name="template-contactform-name" value={this.state.nombre} className="sm-form-control border-form-control required" placeholder="Nombre" />
              </div>
              <div className="col_half col_last contact-form-input">
                <input onChange={e => this.setState({ email: e.target.value})} required
                  type="email" id="template-contactform-email" name="template-contactform-email" value={this.state.email} className="required email sm-form-control border-form-control" placeholder="Correo Electrónico" />
              </div>
              <div className="clear"></div>
              <div className="col_one_third contact-form-input">
                <input onChange={e => this.setState({ telefono: e.target.value})} required
                  type="text" id="template-contactform-phone" name="template-contactform-phone" value={this.state.telefono} className="sm-form-control border-form-control" placeholder="Teléfono" />
              </div>
              <div className="col_two_third col_last contact-form-input">
                <input onChange={e => this.setState({ asunto: e.target.value})} required
                type="text" id="template-contactform-subject" name="subject" value={this.state.asunto} className="required sm-form-control border-form-control" placeholder="Asunto" />
              </div>
              <div className="clear"></div>
              <div className="col_full contact-form-input">
                <textarea onChange={e => this.setState({ mensaje: e.target.value})} required
                className="required sm-form-control border-form-control" id="template-contactform-message" name="template-contactform-message" rows="7" cols="30" placeholder="Mensaje"></textarea>
              </div>

              <div className="col_full center">
                <button className="button button-border button-circle t500 noleftmargin topmargin-sm" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">Enviar Mensaje</button>
                <br/>
              </div>

              <div className="clear"></div>

              <div className="col_full hidden">
                <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" className="sm-form-control" />
              </div>
              <input type="hidden" name="prefix" value="template-contactform-"/>
            </form>

          </div>
        </div>
      </div>
    );
  }
}

export default Contactform;
