import React, { Component } from 'react';


class Dino extends Component {
  render() {
    return (
        <div className="dino" data-0="transform: translateY(0);" data-600="transform: translateY(20px)">
          <svg xmlnsdc="http://purl.org/dc/elements/1.1/" xmlnscc="http://creativecommons.org/ns#" xmlnsrdf="http://www.w3.org/1999/02/22Rdf-syntax-ns#"
              xmlnssvg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"
              xmlSpace="preserve" version="1.1" id="svg2">
              <defs id="defs6">
                  <clipPath id="clipPath16" clipPathUnits="userSpaceOnUse">
                      <path id="path18" d="M 0,36 36,36 36,0 0,0 0,36 Z" />
                  </clipPath>
              </defs>
              <g transform="matrix(1.25,0,0,-1.25,0,45)" id="g10">
                  <g id="g12">
                      <g clipPath="url(#clipPath16)" id="g14">
                          <g transform="translate(17.68,14)" id="g20">
                              <path id="path22" style={{fill:"#00424d", fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="M 0,0 C 0,0 1.937,-7.873 1.187,-9.373 -1.187,-14.119 0,-14 1.187,-14 l 2.373,0 C 5.933,-14 2.373,0 2.373,0 L 0,0 Z"
                              />
                          </g>
                          <g transform="translate(5.2547,16.6134)" id="g24">
                              <path id="path26" style={{fill:"#00424d",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0,0 -0.436,-7.993 -1.187,-9.493 -2.373,-4.747 -2.373,-7.12 -1.186,-7.12 l 2.373,0 c 1.187,0 1.187,4.153 1.187,5.34 0,1.186 1.186,8.603 1.186,8.603 L 0,0 Z"
                              />
                          </g>
                          <g transform="translate(12.3741,30.7493)" id="g28">
                              <path id="path30" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c -2.068,-0.018 -4.209,0.435 -5.523,-2.099 -1.404,-2.709 -2.065,-8.579 0.777,-9.664 2.478,-0.946 7.933,1.187 13.865,-1.186 5.933,-2.373 8.306,-16.612 13.016,-16.631 1.223,-0.004 1.205,-1.123 0,-1.132 -5.914,-0.045 -8.509,5.897 -9.509,5.897 0,0 1,-5.934 0,-5.934 l -4,0 c -1,0 0,3.081 0,4.267 l 0,1.667 c 0,0 -1.593,-1.218 -5.937,-1.218 -3.282,0 -6.063,1.218 -6.063,1.218 0,0 1,-5.934 0,-5.934 l -3.745,0 c -1.678,0 0.165,4.013 0,5.933 -0.102,1.182 -0.511,4.757 -1.224,5.933 -1.726,2.848 -2.763,11.522 -1.929,15.286 0.781,3.526 3.122,6.633 4.675,7.511 1.519,0.858 2.862,1.499 6.19,0.965 C 2.368,4.594 4.184,1.957 3.484,0.605 3.063,-0.206 1.544,0.013 0,0"
                              />
                          </g>
                          <g transform="translate(12.9674,33.8185)" id="g32">
                              <path id="path34" style={{fill:"white",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0,-0.328 -0.265,-0.593 -0.593,-0.593 -0.328,0 -0.594,0.265 -0.594,0.593 0,0.328 0.266,0.593 0.594,0.593 C -0.265,0.593 0,0.328 0,0"
                              />
                          </g>
                          <g transform="translate(10.2182,17.515)" id="g36">
                              <path id="path38" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.094,-0.304 -0.076,-0.626 -0.38,-0.72 -0.303,-0.094 -0.626,0.076 -0.72,0.379 -0.094,0.304 0.076,0.627 0.38,0.721 C -0.417,0.474 -0.094,0.304 0,0"
                              />
                          </g>
                          <g transform="translate(14.0917,16.2665)" id="g40">
                              <path id="path42" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.134,-0.432 -0.108,-0.891 -0.541,-1.025 -0.432,-0.134 -0.891,0.108 -1.025,0.54 -0.134,0.433 0.108,0.892 0.541,1.026 C -0.593,0.674 -0.134,0.432 0,0"
                              />
                          </g>
                          <g transform="translate(12.4675,17.6928)" id="g44">
                              <path id="path46" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.071,-0.23 -0.057,-0.474 -0.288,-0.545 -0.23,-0.072 -0.474,0.057 -0.545,0.287 -0.071,0.23 0.058,0.474 0.287,0.546 C -0.315,0.359 -0.071,0.23 0,0"
                              />
                          </g>
                          <g transform="translate(14.9249,18.0235)" id="g48">
                              <path id="path50" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.067,-0.217 -0.054,-0.448 -0.272,-0.516 -0.217,-0.067 -0.448,0.055 -0.516,0.272 -0.067,0.218 0.055,0.448 0.272,0.516 C -0.298,0.339 -0.067,0.217 0,0"
                              />
                          </g>
                          <g transform="translate(17.7683,17.6741)" id="g52">
                              <path id="path54" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.125,-0.402 -0.101,-0.829 -0.503,-0.954 -0.402,-0.124 -0.829,0.101 -0.953,0.503 -0.125,0.402 0.1,0.829 0.502,0.954 C -0.552,0.627 -0.125,0.402 0,0"
                              />
                          </g>
                          <g transform="translate(22.5215,15.6951)" id="g56">
                              <path id="path58" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.08,-0.257 -0.064,-0.531 -0.322,-0.61 -0.257,-0.08 -0.53,0.064 -0.61,0.321 -0.08,0.258 0.064,0.531 0.322,0.611 C -0.353,0.402 -0.08,0.257 0,0"
                              />
                          </g>
                          <g transform="translate(16.6673,15.6951)" id="g60">
                              <path id="path62" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.08,-0.257 -0.064,-0.531 -0.322,-0.61 -0.257,-0.08 -0.53,0.064 -0.61,0.321 -0.08,0.258 0.064,0.531 0.322,0.611 C -0.353,0.402 -0.08,0.257 0,0"
                              />
                          </g>
                          <g transform="translate(20.8853,17.001)" id="g64">
                              <path id="path66" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.08,-0.257 -0.064,-0.531 -0.322,-0.61 -0.257,-0.08 -0.53,0.064 -0.61,0.321 -0.08,0.258 0.064,0.531 0.322,0.611 C -0.353,0.401 -0.08,0.257 0,0"
                              />
                          </g>
                          <g transform="translate(19.3841,16.8018)" id="g68">
                              <path id="path70" style={{fill:"#00B5D1",fillOpacity:"1",fillRule:"nonzero",stroke:"none"}} d="m 0,0 c 0.118,-0.381 -0.095,-0.785 -0.476,-0.903 -0.381,-0.118 -0.786,0.095 -0.904,0.476 -0.117,0.381 0.096,0.785 0.477,0.903 C -0.522,0.594 -0.118,0.381 0,0"
                              />
                          </g>
                      </g>
                  </g>
              </g>
          </svg>
        </div>
    );
  }
}

export default Dino;
