import React, {Component} from 'react';
import SimpleMenu from '../SimpleMenu/SimpleMenu';
import SimpleFooter from '../SimpleFooter/SimpleFooter';

class RegistroAngular extends Component {
  componentDidMount() {
    document.body.scrollTop = 0;
  }

  render() {
    return (
      <div>
        <SimpleMenu />
        <div id="wrapper" className="section nobg mb-0">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXRtTeQYBwcQYkckZqRHRonsB65dY4uK2ZDKgUY_HIZLziDA/viewform?embedded=true" width="95%" height="1234" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
        </div>
        <SimpleFooter />
      </div>
    );
  }
}

export default RegistroAngular;
