import React, { Component } from 'react';
import SimpleMenu from '../SimpleMenu/SimpleMenu';

class Article1 extends Component {

  componentDidMount() {
      document.body.scrollTop = 0;
  }
      
  render() {
    return (
      <div>
        <SimpleMenu />
        <main id="feed">
          <div className="content">
            <div className="article">
              <div className="type"><span className="editorial">Editorial</span></div>
                <h4>La importancia de la programación</h4>
                <div className="header"><u>Stephanie Ospina</u> | <u>13/04/19</u></div>
                <p>
                  Para entender por qué la programación de una computadora es importante para el mundo moderno, hay que entender primero qué es una computadora. Una computadora es una herramienta tonta para realizar muchas tareas repetitivas, eficientemente (tiempo o espacio). 
                  Una computadora no puede analizar un problema y encontrar una solución. Los seres humanos, por otro lado, son muy buenos analizando y resolviendo problemas, pero se aburren fácilmente con tareas repetitivas.
                </p>

                <p>
                  Los seres humanos, al utilizar sus habilidades analíticas y de resolución de problemas, pueden crear algoritmos (conjunto finito de instrucciones, trabajar con un conjunto finito de entradas para producir una salida) para problemas computables. Una computadora puede seguir estas instrucciones y producir una solución.
                </p>

                <p>
                  Ahora a las cosas realmente importantes. La programación es un proceso, con 3 fases muy importantes: Identificación de problemas y fase de resolución, fase de implementación y fase de mantenimiento. Los tres son importantes para que los programadores de computadoras / desarrolladores de software puedan resolver problemas. Muchos desarrolladores igualarían el proceso de programación con solo la implementación de algoritmos, lo cual es incorrecto. La traducción de un algoritmo a un lenguaje de programación, que luego puede ser compilado o interpretado y finalmente comprendido por una computadora, es solo una parte del proceso de programación. En mi humilde opinión, también es la tarea más fácil.
                </p>

                <p>
                  En resumen, la programación informática es un proceso mediante el cual se resuelve un problema.

                  En el mundo moderno, dado que la cantidad de problemas y su complejidad se multiplican, se trata de una herramienta para obtener soluciones cuidadosamente, enviando las tareas repetitivas a la computadora y logrando así la "automatización", mientras tanto se requieren aportaciones de programadores / desarrolladores de software.
                </p>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Article1;
