import React, { Component } from 'react';
import SimpleFooter from '../SimpleFooter/SimpleFooter';
import laptop from '../images/hero-1.jpg';
import { NavLink } from 'react-router-dom';
import Clases1 from '../images/clases-1.jpg';
import Clases2 from '../images/clases-2.jpg';
import Clases3 from '../images/clases-3.jpg';
import Clases4 from '../images/clases-4.jpg';
import SimpleMenu from '../SimpleMenu/SimpleMenu';
import ContactFormNav from '../ContactFormNav/ContactFormNav';
import Popup from 'reactjs-popup';

class FrontEnd extends Component {
  constructor() {
    super()
  }

  componentDidMount() {
    document.body.scrollTop = 0;
  }

  render() {

    const Modal =  () => (
      <Popup
        id="modal-contact"
        trigger={<button className="button button-large button-rounded button-shadow"> Contáctanos </button>}
        modal
        closeOnDocumentClick
        contentStyle={{ width: '80%' }}
      >
        <span> <ContactFormNav /> </span>
      </Popup>
    )

    return (
      <div>
        <SimpleMenu />

        <div id="wrapper" className="clearfix">
          <section id="content">
            <div className="content-wrap nopadding">

              <div className="section nobg topmargin-lg mb-0">
                <div className="container center">
                  <NavLink to="/registro-front-end" className="button button-large button-rounded button-shadow">Inicia tu aplicación</NavLink>
                  <Modal />
                </div>
                <div className="container">
                  <div className="heading-block divcenter center" style={{maxWidth: '600px'}}>
                    <small className="text-black-50 uppercase ls3 t600">Programa:</small>
                    <h2 className="capitalize t600 ls0 mb-1">Front End Development</h2>
                    <p>CAMBIA TU VIDA! Aprende una de las habilidades con mayor demanda: <b>Programación de Web</b> en nuestro curso de 12 semanas!.</p>
                  </div>
                  <div className="clear"></div>
                  <div className="row showcase-section align-items-center justify-content-between clearfix">
                    <div className="col-lg-3 col-md-5">
                      <ul className="nobottommargin">
                        <li className="showcase-feature" data-target="#target-1">
                          <h3>Por qué?</h3>
                            <p>Estamos rodeados de computadores y hoy mas que nunca se requiren personas que sepan 'hablar' con estas maquinas!  Adicional, la industria de Tecnología esta llena de oportunidades de trabajo y excelentes salarios.</p>
                        </li>
                        <li className="showcase-feature" data-target="#target-2">
                          <h3>Beneficios:</h3>
                            <p>Cientos de oportunidades, trabajos y excelentes salarios. En promedio los desarrolladores de Software ganan entre $3.5 y $7 millones COP</p>
                        </li>
                        <li className="showcase-feature" data-target="#target-3">
                          <h3>A quienes esta dirigido:</h3>
                            <p>A todas las personas interesadas en Tecnología, NO requerimos conocimientos previos en computación.</p>
                        </li>
                        <li className="showcase-feature" data-target="#target-4">
                          <h3>Requerimientos:</h3>
                            <p>Computador, conexión a internet y disponibilidad de tiempo.</p>
                        </li>
                        <li className="showcase-feature" data-target="#target-4">
                          <h3>Qué obtienes con el programa:</h3>
                            <p>Certificado, asesoria profesional, acceso ilimitado a la plataforma virtual, 20% de descuento en cursos y artículos.</p>
                        </li>
                      </ul>
                    </div>

                    <div className="col-lg-8 col-md-7" >
                      <div id="target-1" className="showcase-target showcase-target-active">
                        <img src={laptop} alt="Bootcamp de programacion de web"></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section bg1 mt-4">
                <div className="container">
                  <div className="heading-block divcenter center" >
                    <h2 className="capitalize t600 mb-2" >Descripción del Programa</h2>
                    <br/>
                    <p>Nuestro programa de front-end proporciona las habilidades necesarias para desarrollar una carrera en el desarrollo de web. Desde los principios de UX / UI hasta las bases sólidas en HTML, CSS y JavaScript, nuestro plan de estudios proporciona las herramientas para crear aplicaciones de escritorio, móviles y web.</p>
                    <br/>
                    <p>Nuestro programa ha sido diseñado por expertos en la industria de Tecnología. El programa online consta de 3 bloques, cada uno tiene una duración de 4 semanas</p>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-lg-6">
                      <div className="media row">
                        <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases1} alt="Bootcamp de programacion de web" width="230"></img>
                        <div className="col-sm-7 media-body">
                          <h3 className="mb-3 t600">Primer Bloque</h3>
                          <p className="mb-3 text-black-50">Aprenderás HTML, CSS, GitHub y Git workflow, JavaScript básico y unit testing</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 mt-5 mt-lg-0">
                      <div className="media row">
                        <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases2} alt="Bootcamp de programacion de web" width="230"></img>
                        <div className="col-sm-7 media-body">
                          <h3 className="mb-3 t600">Segundo Bloque</h3>
                          <p className="mb-3 text-black-50">En este bloque aprenderás JavaScript Avanzado, ES6, algoritmos e introducción a React y Redux.</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 mt-5">
                      <div className="media row">
                        <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases3} alt="Bootcamp de programacion de web" width="230"></img>
                        <div className="col-sm-7 media-body">
                          <h3 className="mb-3 t600">Tercer Bloque</h3>
                          <p className="mb-3 text-black-50">Realizaras tu portafolio, contribuirás a un proyecto de open source y pondrás en practica todo lo aprendido.</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 mt-5">
                      <div className="media row">
                        <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases4} alt="Bootcamp de programacion de web" width="230"></img>
                        <div className="col-sm-7 media-body">
                          <h3 className="mb-3 t600">Proyecto final</h3>
                          <p className="mb-3 text-black-50">Para obtener el certificado debes presentar el Capstone, tu proyecto final!.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section nobg" >
                <div className="container">
                  <div className="heading-block divcenter nobottomborder center" >
                    <div className="heading-block nobottomborder mb-0">
                      <small className="text-black-50 uppercase ls3 t600">Inversión: </small>
                      <h2 className="capitalize t600 mb-2">$990 US</h2>
                    </div>
                    <h2 className="nott t600 mb-2" ><span className="counter counter-inherit text-dark inline-block"></span> Nuestro próximo curso inicia el<span> 18 de Enero de 2020!</span></h2>
                    <p className="lead">Animate a dar este paso que cambiará tu vida.</p>
                  </div>
                </div>
              </div>

              <div id="section-price" className="section" >
                <div className="container">
                  <h1 className="nott t600 mb-2" ><span className="counter counter-inherit text-dark inline-block">PRÓXIMOS CURSOS</span></h1>                
                  <div className="row justify-content-between">
                    <table className='date-table'> 
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Fecha de Inicio</th>
                          <th>Fecha final</th>
                          <th>Horario</th>                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Bootcamp Desarrollo de Web</td>
                          <td>18/01/20</td>
                          <td>11/04/20</td>
                          <td>Martes y Jueves 6 - 9pm, Sabado 10am - 12pm</td>
                        </tr>
                        <tr>
                          <td>Bootcamp Desarrollo de Web</td>
                          <td>30/05/20</td>
                          <td>22/08/20</td>
                          <td>Martes y Jueves 6 - 9pm, Sabado 10am - 12pm</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="section nobg clearfix">
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-lg-6">
                      <div className="heading-block nobottomborder mb-0">
                        <small className="text-black-50 uppercase ls3 t600">Contactanos</small>
                        <h2 className="capitalize t600 mb-2">Tienes Preguntas?!</h2>
                        <p className="lead">Nuestro equipo puede ayudarte.</p>
                      </div>
                    </div>

                    <div className="col-lg-6 d-inline-block d-sm-inline-flex justify-content-start justify-content-lg-end">
                      <NavLink to="/registro-front-end" className="button button-large button-rounded button-shadow">Inicia tu aplicación</NavLink>
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <SimpleFooter />
      </div>
    );
  }
}

export default FrontEnd;
