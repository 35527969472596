import React, { Component } from 'react';
import SimpleFooter from '../SimpleFooter/SimpleFooter';
import uxPic from '../images/iphone-solid.png';
import { NavLink } from 'react-router-dom';
import ClasesUX1 from '../images/clasesUX-1.jpg';
import ClasesUX2 from '../images/clasesUX-2.jpg';
import ClasesUX3 from '../images/clasesUX-3.jpg';
import ClasesUX4 from '../images/clasesUX-4.jpg';
import SimpleMenu from '../SimpleMenu/SimpleMenu';
import ContactFormNav from '../ContactFormNav/ContactFormNav';
import Popup from 'reactjs-popup';

class UX extends Component {
  constructor() {
    super()

    this.state = {
      displayContactForm: false
    }
  }

  componentDidMount() {
    document.body.scrollTop = 0;
  }

  render() {

    const Modal =  () => (
      <Popup
        id="modal-contact"
        trigger={<button className="button button-large button-rounded button-shadow"> Contáctanos </button>}
        modal
        closeOnDocumentClick
        contentStyle={{ width: '80%' }}
      >
        <span> <ContactFormNav /> </span>
      </Popup>
    )

    if (!this.state.displayContactForm) {
      return (
        <div>
          <SimpleMenu />

          <div id="wrapper" className="clearfix">
            <section id="content">
              <div className="content-wrap nopadding">

                <div className="section nobg topmargin-lg mb-0">
                  <div className="container center">
                    <NavLink to="/registro-ux" data-lightbox="inline" className="button button-large button-rounded button-shadow">Inicia tu aplicación</NavLink>
                    <Modal />
                  </div>
                  <div className="container">
                    <div className="heading-block divcenter center" style={{maxWidth: '600px'}}>
                      <small className="text-black-50 uppercase ls3 t600">Programa:</small>
                      <h2 className="capitalize t600 ls0 mb-1">CURSO DE EXPERIENCIA DE USUARIO (UX & DISEÑO)</h2>
                      <p>AMANTE DEL DISEÑO? Aprende una de las habilidades con mayor demanda en el ámbito laboral: <b>UX: User Experience</b> en nuestro curso de 4 semanas!</p>
                    </div>
                    <div className="clear"></div>
                    <div className="row showcase-section align-items-center justify-content-between clearfix">
                      <div className="col-lg-3 col-md-5">
                        <ul className="nobottommargin">
                          <li className="showcase-feature" data-target="#target-1">
                            <h3>Por qué?</h3>
                              <p>Es una de las habilidades más requeridas y solicitadas por empresas de tecnología alrededor del mundo! Adicional, la industria de Tecnología esta llena de oportunidades de trabajo y excelentes salarios.</p>
                          </li>
                          <li className="showcase-feature" data-target="#target-2">
                            <h3>Beneficios:</h3>
                              <p>Cientos de oportunidades, trabajos, buenos ingresos y la oportunidad de ser freelancer.</p>
                          </li>
                          <li className="showcase-feature" data-target="#target-3">
                            <h3>A quienes esta dirigido:</h3>
                              <p>A todas las personas interesadas en Tecnología, NO requerimos conocimientos previos en computación.</p>
                          </li>
                          <li className="showcase-feature" data-target="#target-4">
                            <h3>Requerimientos:</h3>
                              <p>Computador, conexión a internet y disponibilidad de tiempo.</p>
                          </li>
                          <li className="showcase-feature" data-target="#target-4">
                            <h3>Qué obtienes con el programa:</h3>
                              <p>Certificado, asesoría profesional, 10% de descuento en cursos y artículos.</p>
                          </li>
                        </ul>
                      </div>

                      <div className="col-lg-8 col-md-7" >
                        <div id="target-1" className="showcase-target showcase-target-active">
                          <img src={uxPic} alt="curso de UX"></img>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="section bg1 mt-4">
                  <div className="container">
                    <div className="heading-block divcenter center" >
                      <h2 className="capitalize t600 mb-2" >Descripción del Programa</h2>
                      <br/>
                      <p>El diseño de UX es el proceso de diseño de productos (digitales o físicos) que son útiles, fáciles de usar y agradables para interactuar. Se trata de mejorar la experiencia que tienen las personas al interactuar con un producto y asegurarse de que encuentren valor en lo que está proporcionando. Cuando aplicamos este concepto al diseño de Apps y Paginas de Internet, la meta es lograr un diseño intuitivo, facil y agradable de usar.</p>
                      <br/>
                      <br/>
                      <p>Después de 4 semanas, podrás hacer un boceto de ideas, estructura, estilo con color, tipografía e imágenes, ¡y tendrás tu primer proyecto finalizado!</p>
                    </div>

                    <div className="row justify-content-between">
                      <div className="col-lg-6">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={ClasesUX1} alt="Curso Experiencia de usuario" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Primer Semana</h3>
                            <p className="mb-3 text-black-50">Aprenderás los principios básicos de diseño, ideación y sketching</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-5 mt-lg-0">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={ClasesUX2} alt="curso de UX experiencia del usuario" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Segunda Semana</h3>
                            <p className="mb-3 text-black-50">Aprenderás la teoría de los colores, tipografía y uso de imágenes.</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-5">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={ClasesUX3} alt="curso de UX" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Tercer Semana</h3>
                            <p className="mb-3 text-black-50">Aprenderás layout y composición.</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-5">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={ClasesUX4} alt="curso de UX experiencia de usuario" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Cuarta Semana</h3>
                            <p className="mb-3 text-black-50">Para obtener el certificado debes presentar el Capstone, tu proyecto final!.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section nobg" >
                  <div className="container">
                    <div className="heading-block divcenter nobottomborder center" >
                      <div className="heading-block nobottomborder mb-0">
                        <small className="text-black-50 uppercase ls3 t600">Inversión: </small>
                        <h2 className="capitalize t600 mb-2">$350.000</h2>
                      </div>
                      <h2 className="nott t600 mb-2" ><span className="counter counter-inherit text-dark inline-block"></span> Nuestro próximo curso inicia el<span> 24 de agosto de 2020</span></h2>
                      <p className="lead">Anímate a dar este paso que cambiará tu vida.</p>
                    </div>
                  </div>
                </div>

                <div id="section-price" className="section" >
                  <div className="container">
                    <h1 className="nott t600 mb-2" ><span className="counter counter-inherit text-dark inline-block">PRÓXIMOS CURSOS</span></h1>                
                    <div className="row justify-content-between">
                      <table className='date-table'> 
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Fecha de Inicio</th>
                            <th>Fecha final</th>
                            <th>Horario</th>                          
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Curso de UX</td>
                            <td>24/08/20</td>
                            <td>14/09/20</td>
                            <td>Lunes 6:30pm - 8:30pm</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="section nobg clearfix">
                  <div className="container">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-lg-6">
                        <div className="heading-block nobottomborder mb-0">
                          <small className="text-black-50 uppercase ls3 t600">Contáctanos</small>
                          <h2 className="capitalize t600 mb-2">Tienes Preguntas?!</h2>
                          <p className="lead">Nuestro equipo puede ayudarte.</p>
                        </div>
                      </div>

                      <div className="col-lg-6 d-inline-block justify-content-start justify-content-lg-end">
                        <NavLink to="/registro-ux" className="button button-xlarge ls0 button-rounded button-border nott t400 ">Inicia tu aplicación</NavLink>
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <SimpleFooter />
        </div>
      );
    }
  }
}

export default UX;
