import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SimpleMenu from '../SimpleMenu/SimpleMenu';

class NotFound extends Component {

  render() {
    return (
        <div className="container clearfix" id="contact">
          <SimpleMenu />

          <section id="page-title">
            <div className="container clearfix">
              <h1>Page Not Found</h1>
            </div>
          </section>

          <section id="content">
            <div className="content-wrap">
              <div className="container clearfix">
                <div className="col_half nobottommargin">
                  <div className="error404 center">404</div>
                </div>

                <div className="col_half nobottommargin col_last">
                  <div className="heading-block nobottomborder">
                    <h4>Ooopps.! The Page you were looking for, couldn't be found.</h4>
                    <span>Try searching for the best match or browse the links below:</span>
                  </div>

                  <div className="col_one_third widget_links topmargin nobottommargin">
                    <ul>
                      <li><NavLink to="/">Home</NavLink></li>
                      <li><NavLink to="/">FAQs</NavLink></li>
                    </ul>
                  </div>

                  <div className="col_one_third widget_links topmargin nobottommargin">
                    <ul>
                      <li><NavLink to="/calendario-cursos-programacion">Workshops</NavLink></li>
                      <li><NavLink to="/curso-ux">UX</NavLink></li>
                    </ul>
                  </div>

                  <div className="col_one_third widget_links topmargin nobottommargin col_last">
                    <ul>
                      <li><NavLink to="/bootcamp-web-development">Bootcamp Desarrollo de Web</NavLink></li>
                      <li><NavLink to="/">Contácto</NavLink></li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
    );
  }
}

export default NotFound;