import React, { Component } from 'react';
import Menu from '../Menu/Menu';
import ContactForm from '../ContactForm/ContactForm';
import '../css/animate.css';
import '../css/bootstrap.css';
import '../css/et-line.css';
import '../css/font-icons.css';
import '../css/fonts.css';
import '../css/magnific-popup.css';
import '../css/onepage.css';
import '../css/responsive.css';
import '../css/style.css';
import '../css/main.css';
import '../css/bike.css';
import '../css/feed.css';
import Slider from '../Slider/Slider';
import Footer from '../Footer/Footer';

class Main extends Component {
  render() {
    return (
      <div>
       <Menu />
       <Slider />
       <ContactForm />
       <Footer />
      </div>
    );
  }
}

export default Main;
