import React, { Component } from 'react';
import Clases1 from '../images/clases-1.jpg';
import ClasesUX1 from '../images/clasesUX-1.jpg';
import ReactJsPic from '../images/react.jpg';
import AngularPic from '../images/angular.png';

class WorkshopsData extends Component {

  futureWorkshops() {
    return [
      // { pic: Clases1,
      //   day: '18',
      //   month: 'Enero',
      //   title: 'Bootcamp Desarrollo de Web',
      //   badge: 'open',
      //   time: 'Martes y Jueves 6 - 9pm y Sábados de 10am - 12pm, (12 semanas)',
      //   place: 'Online (Virtual – Plataforma en VIVO)',
      //   description: 'Apende Web Development, programacion de web! Este programa incluye teoria y practica con HTML, CSS, JavaScript y Frameworks.',
      //   path: '/bootcamp-web-development',
      //   registro: '/registro-front-end'
      // },
      { pic: ClasesUX1,
        day: '24',
        month: 'Agosto',
        title: 'CURSO DE UX & DISEÑO',
        badge: 'Open',
        time: 'Lunes 6:30pm - 8:30pm, (4 semanas)',
        place: 'Online (Virtual – Zoom)',
        description: 'Aprenderás todo acerca de UX. Incluyendo bocetos de ideas, estructura, estilo con color, tipografía e imágenes, ¡y tendrás tu primer proyecto finalizado!',
        path: '/curso-ux',
        registro: '/registro-ux'
      },
      // { pic: ReactJsPic,
      //   day: '08',
      //   month: 'Febrero',
      //   title: 'Taller de React',
      //   badge: 'open',
      //   time: 'Sábados de 3pm - 6pm, (4 semanas)',
      //   place: 'Online (Virtual – Plataforma en VIVO)',
      //   description: 'Apende a usar una de las librerias más populares, React! en nuestro taller de 4 semanas.',
      //   path: '/curso-react',
      //   registro: '/registro-curso-react'
      // },
      // { pic: AngularPic,
      //   day: '02',
      //   month: 'Marzo',
      //   title: 'CURSO DE ANGULAR',
      //   badge: 'Open',
      //   time: 'Lunes 6:00pm - 9:00pm, (4 semanas)',
      //   place: 'Online (Virtual – Plataforma en VIVO)',
      //   description: 'Apende a usar una de las librerias más populares, Angular! en nuestro taller de 4 semanas.',
      //   path: '/curso-angular',
      //   registro: '/registro-curso-angular'
      // },
      // { pic: Clases1,
      //   day: 30,
      //   month: 'Mayo',
      //   title: 'Bootcamp Desarrollo de Web',
      //   badge: 'open',
      //   time: 'Martes y Jueves 6 - 9pm y Sábados de 10am - 12pm, (12 semanas)',
      //   place: 'Online (Virtual – Plataforma en VIVO)',
      //   description: 'Apende Web Development, programacion de web! Este programa incluye teoria y practica con HTML, CSS, JavaScript y Frameworks.',
      //   path: '/bootcamp-web-development',
      //   registro: '/registro-front-end'
      // },
    ]
  }
}

export default WorkshopsData;
