import React, { Component } from 'react';
import logo from '../images/logo.png';
import { NavLink } from 'react-router-dom';
import ContactFormNav from '../ContactFormNav/ContactFormNav';
import Popup from 'reactjs-popup';

class SimpleMenu extends Component {

  displayMenu() {
    const el = document.querySelector('.phone-menu');
    if(el.classList.contains('open')) {
      el.classList.remove('open')
      document.querySelector('.menu-circle-list').classList.add('hide');
    } else {
      el.classList.add('open');
      document.querySelector('.menu-circle-list').classList.remove('hide');    
    } 
  }

  render() {

    const Modal =  () => (
      <Popup
        id="modal-contact"
        trigger={<a> Contáctanos </a>}
        modal
        closeOnDocumentClick
        contentStyle={{ width: '80%' }}
      >
        <span> <ContactFormNav /> </span>
      </Popup>
    )

    return (
      <div> 
        <header id="header" className="full-header static-sticky" datastickyclass="not-dark" datastickyoffset="full" datastickyoffsetnegative="200">
          <div id="header-wrap">
            <div className="container clearfix">
              <div id="logo">
                <NavLink to="/" className="standard-logo"><img src={logo} alt="Bootcamp de programacion y cursos de tecnologia"/></NavLink>
              </div>
              <nav id="primary-menu">
                <div className="phone-query phone-menu" onClick={(event) => this.displayMenu(event)}>
                  <div className="menu-line"></div>
                  <div className="menu-line"></div>
                  <div className="menu-line"></div>
                </div>
                <div className="phone-query menu-circle-list hide" data-easing="easeInOutExpo" data-speed="1250" data-offset="65">
                  <div className="menu-circle">
                    <NavLink to="/">
                        Home
                    </NavLink>
                  </div>
                  <div className="menu-circle">
                    <NavLink to="/calendario-cursos-programacion">
                        Clases
                    </NavLink>
                  </div>
                  <div className="menu-circle">
                    <Modal />
                  </div>
                </div>
                <ul className="one-page-menu" data-easing="easeInOutExpo" data-speed="1250" data-offset="65">
                  <li><NavLink to="/" href="#splash" data-href="#wrapper"><div>Home</div></NavLink></li>
                  <li><NavLink to="/calendario-cursos-programacion" href="#splash" data-href="#wrapper"><div>Clases</div></NavLink></li>
                  <li className="cursor-pointer"><Modal /></li>                  
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default SimpleMenu;
