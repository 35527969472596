import React, { Component } from 'react';
import Dino from '../images/Dino';
import clases1 from '../images/clases-1.jpg';
import Background from '../images/stock5.jpg';
import { NavLink } from 'react-router-dom';

class Slider extends Component {

  componentDidMount() {
    document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className="App stretched" dataloader="11" dataloadercolor="#543456">
        <div id="wrapper" className="clearfix">
          <main id="splash" className="main-slider">
            <div className="content">
              <div className="dino phone-query">
                <Dino />
              </div>

              <div className="text">
                <h1>Mantente
                  <br/>Relevante.</h1>
                <h2> Ofrecemos Cursos y Servicios de Programación y Automatización para ayudar a personas y empresas a sobrevivir y prosperar en el mundo de la tecnología.</h2>
                <div className="links">
                  <a href="#section-home" className="hexagon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 4h5v8l-2.5-1.5L6 12V4z" />
                    </svg>
                    <span>Clases y Talleres</span>
                  </a>
                  <NavLink to="/calendario-cursos-programacion" className="hexagon">
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"
                      />
                    </svg>
                    <span>Calendario y Programación</span>
                  </NavLink>
                  <a href="#section-services" className="hexagon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                      <path d="M0 0h48v48H0z" fill="none" />
                      <path d="M24 14V6H4v36h40V14H24zM12 38H8v-4h4v4zm0-8H8v-4h4v4zm0-8H8v-4h4v4zm0-8H8v-4h4v4zm8 24h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm20 24H24v-4h4v-4h-4v-4h4v-4h-4v-4h16v20zm-4-16h-4v4h4v-4zm0 8h-4v4h4v-4z"
                      />
                    </svg>
                    <span>Servicios Empresariales</span>
                  </a>
                </div>
              </div>

              <div className="dino web-query">
                <Dino />
              </div>

            </div>
          </main>
          
          <section id="content">
            <div className="content-wrap nopadding">
              <div id="section-home" className="page-section" style={{backgroundColor: "#F5F5F5"}}>
                <div className="container clearfix">
                  <h2 className="divcenter font-body" style={{maxWidth: '700px', fontSize: '40px', fontWeight: '700'}}>Clases & Talleres</h2>
                  <br/>
                  <h2 className="divcenter bottommargin-lg font-body center" style={{maxWidth: "700px", fontSize: "27px"}}><b>Aprende Programación!</b> Crea aplicaciones de Web, páginas de Internet y más. En nuestros cursos aprenderás los fundamentos de la programación que serán esenciales para una carrera exitosa como <span>Desarrollador de Software.</span></h2>
                  <br/>                
                  {/* <div id="slider-services" className="carousel-widget" datamargin="20" dataloop="true" datanav="false" datapagi="true" dataautoplay="5000" dataitemssm="1" dataitemsmd="2" dataitemsxl="3">
                    <div className="intro-services center col-padding" style={{backgroundColor: "#FFF"}}>
                      <i className="i-plain color i-xlarge icon-et-browser inline-block"></i>
                      <div className="heading-block nobottomborder">
                        <h4>Bootcamp Desarrollo de Web</h4>
                        <p className="text-muted">Curso Intensivo.</p>
                      </div>
                      <div><NavLink to="/bootcamp-web-development" className="button button-rounded button-small t600">Ver más</NavLink></div>
                    </div>
                  </div> */}

                  <h2 className="divcenter font-body" style={{maxWidth: '700px', fontSize: '40px', fontWeight: '700'}}>Cursos y Talleres</h2>
                  <br/>                  
                  <div id="slider-services" className="owl-carousel">
                    
                    <div className="intro-services center col-padding" style={{backgroundColor: "#FFF"}}>
                      <i className="i-plain color i-xlarge icon-et-paintbrush inline-block" style={{marginBottom: "15px"}}></i>
                      <div className="heading-block nobottomborder" style={{marginBottom: "15px"}} >
                        <h4>CURSO DE <br/>UX & DISEÑO</h4>
                        <p className="text-muted">Curso Intensivo.</p>
                      </div>
                      <div><NavLink to="/curso-ux" className="button button-rounded button-small t600">Ver más</NavLink></div>
                    </div>
                    
                    <div className="intro-services center col-padding" style={{backgroundColor: "#FFF"}}>
                      <i className="i-plain color i-xlarge icon-et-laptop inline-block" style={{marginBottom: "15px"}}></i>
                      <div className="heading-block nobottomborder" style={{marginBottom: "15px"}} >
                        <h4>React Js</h4>
                        <p className="text-muted">Taller.</p>
                      </div>
                      <div><NavLink to="/curso-react" className="button button-rounded button-small t600">Ver más</NavLink></div>
                    </div>
                    
                    <div className="intro-services center col-padding" style={{backgroundColor: "#FFF"}}>
                      <i className="i-plain color i-xlarge icon-et-browser inline-block" style={{marginBottom: "15px"}}></i>
                      <div className="heading-block nobottomborder" style={{marginBottom: "15px"}} >
                        <h4>Angular Js</h4>
                        <p className="text-muted">Taller.</p>
                      </div>
                      <div><NavLink to="/curso-angular" className="button button-rounded button-small t600">Ver más</NavLink></div>
                    </div>


                  </div>
                  <div id="slider-services" className="carousel-widget" datamargin="20" dataloop="true" datanav="false" datapagi="true" dataautoplay="5000" dataitemssm="1" dataitemsmd="2" dataitemsxl="3">
                    <div className="intro-services center col-padding" style={{backgroundColor: "#FFF"}}>
                      <i className="i-plain color i-xlarge icon-et-laptop inline-block"></i>
                      <div className="heading-block nobottomborder" style={{marginBottom: "15px"}} >
                        <h4>Ver Calendario</h4>
                        <p className="text-muted">Workshops y Clases.</p>
                      </div>
                      <div><NavLink to="/calendario-cursos-programacion" className="button button-rounded button-small t600">Ver más</NavLink></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div id="section-services" className="notoppadding">
            <div className="section nomargin">
              <div className="container clearfix">
                <h2 className="divcenter font-body" style={{maxWidth: '700px', fontSize: '40px', fontWeight: '700', marginBottom:'50px'}}>Servicios Empresariales</h2>
                <div className="divcenter center" style={{maxWidth: "900px"}}>
                  <h2 className="nobottommargin t300 ls1">A través de la capacitación y el soporte continuo, lo ayudaremos a desarrollar la hablidad organizativa para construir un programa de automatización eficaz y sostenible.
                    <br/>
                    <br/>
                    Nuestro enfoque de 360° proporciona soporte técnico y comercial a todos los niveles. Ya sea que esté seleccionando un proveedor, configurando un ecosistema o incluso solucionando problemas con robots, estaremos allí para ofrecer soluciones, no solo respuestas.</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-lg-4 d-md-block" style={{backgroundImage: `url(${Background}`, backgroundSize: "cover", backgroundPositionX: 'center'}}></div>
              <div className="col-lg-8">
                <div>
                  <div className="row align-items-stretch grid-border clearfix">
                    <div className="col-lg-4 col-md-6 col-padding">
                      <div className="feature-box fbox-center fbox-dark fbox-plain fbox-small nobottomborder">
                        <div className="fbox-icon">
                          <i className="icon-et-mobile"></i>
                        </div>
                        <h3>Entrenamiento en estrategias para generar nuevos negocios</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-padding">
                      <div className="feature-box fbox-center fbox-dark fbox-plain fbox-small nobottomborder">
                        <div className="fbox-icon">
                          <i className="icon-et-presentation"></i>
                        </div>
                        <h3>RPA & BPM para Developers</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-padding">
                      <div className="feature-box fbox-center fbox-dark fbox-plain fbox-small nobottomborder">
                        <div className="fbox-icon">
                          <i className="icon-et-puzzle"></i>
                        </div>
                        <h3>.NET & Scripting para automatización</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-padding">
                      <div className="feature-box fbox-center fbox-dark fbox-plain fbox-small nobottomborder">
                        <div className="fbox-icon">
                          <i className="icon-et-gears"></i>
                        </div>
                        <h3>RFP & Seleccion del vendedor</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-padding">
                      <div className="feature-box fbox-center fbox-dark fbox-plain fbox-small nobottomborder">
                        <div className="fbox-icon">
                          <i className="icon-et-genius"></i>
                        </div>
                        <h3>CoE Stand-Up & Roadmapping</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-padding">
                      <div className="feature-box fbox-center fbox-dark fbox-plain fbox-small nobottomborder">
                        <div className="fbox-icon">
                          <i className="icon-et-hotairballoon"></i>
                        </div>
                        <h3>Configuración de herramientas & soporte para Bots</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="section dark nomargin">
            <div className="divcenter center" style={{maxWidth: "900px"}}>
              <h2 className="nobottommargin t300 ls1">Quieres conocer más? cuéntanos acerca de tu proyecto <a href="#contact" data-scrollto="#template-contactform" data-offset="140" data-easing="easeInOutExpo" data-speed="1250" className="button button-border button-circle button-large notopmargin nobottommargin" style={{position: "relative", top: "-3px"}}>Contáctanos</a></h2>
            </div>
          </div>

          <div id="section-about" className="center" style={{padding: "100px 0 0"}}>
            <div className="container clearfix">
              <h2 className="divcenter bottommargin font-body" style={{maxWidth: '700px', fontSize: '40px', fontWeight: '700'}}>Acerca de Nosotros</h2>
              <p className="lead divcenter bottommargin" style={{maxWidth: '800px'}}>Tech Fluent fue fundada por un grupo de consultores que reconocieron un problema con la forma en que funcionan las empresas hoy en día. La experiencia técnica está altamente concentrada entre pequeños grupos de especialistas. En una era donde la tecnología es la base de todo lo que hacemos, esto tiene un impacto profundamente negativo en todo, desde la estrategia de operaciones hasta el reclutamiento y la seguridad.
                <br/><br/> Hemos visto el poder de las soluciones digitales para transformar radicalmente las operaciones diarias y hacer la vida más fácil para los empleados, las empresas y las personas. Pero solo se puede realizar cuando reconocemos la importancia de la fluidez tecnológica en todos los niveles de una organización.</p>
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Slider;
