import React, { Component } from 'react';
import SimpleFooter from '../SimpleFooter/SimpleFooter';
import { NavLink } from 'react-router-dom';
import Clases1 from '../images/clases-1.jpg';
import Clases2 from '../images/clases-2.jpg';
import Clases3 from '../images/clases-3.jpg';
import Clases4 from '../images/clases-4.jpg';
import ReactJsPic from '../images/react.png';
import SimpleMenu from '../SimpleMenu/SimpleMenu';
import ContactFormNav from '../ContactFormNav/ContactFormNav';
import Popup from 'reactjs-popup';

class ReactJs extends Component {
  constructor() {
    super()

    this.state = {
      displayContactForm: false
    }
  }

  componentDidMount() {
    document.body.scrollTop = 0;
  }

  render() {

    const Modal =  () => (
      <Popup
        id="modal-contact"
        trigger={<button className="button button-large button-rounded button-shadow"> Contáctanos </button>}
        modal
        closeOnDocumentClick
        contentStyle={{ width: '80%' }}
      >
        <span> <ContactFormNav /> </span>
      </Popup>
    )

    if (!this.state.displayContactForm) {
      return (
        <div>
          <SimpleMenu />

          <div id="wrapper" className="clearfix">
            <section id="content">
              <div className="content-wrap nopadding">

                <div className="section nobg topmargin-lg mb-0">
                  <div className="container center">
                    <NavLink to="/registro-curso-react" data-lightbox="inline" className="button button-large button-rounded button-shadow">Inicia tu aplicación</NavLink>
                    <Modal />
                  </div>
                  <div className="container">
                    <div className="heading-block divcenter center" style={{maxWidth: '600px'}}>
                      <small className="text-black-50 uppercase ls3 t600">Programa:</small>
                      <h2 className="capitalize t600 ls0 mb-1">TALLER DE REACT JS</h2>
                      <p>CAMBIA TU VIDA! <b>Aprende React</b>, una de las herramientas más usadas en Web Development en nuestro taller de 4 semanas!</p>
                    </div>
                    <div className="clear"></div>
                    <div className="row showcase-section align-items-center justify-content-between clearfix">

                      <div className=" col-md-9" style={{margin: 'auto'}}>
                        <div id="target-1">
                          <img src={ReactJsPic} alt="curso de UX"></img>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="section bg1 mt-4">
                  <div className="container">
                    <div className="heading-block divcenter center" >
                      <h2 className="capitalize t600 mb-2" >Descripción del Programa</h2>
                      <br/>
                      <p>React.js es una libreria de JavaScript usada para crear fácilmente interfaces interactivas. Es utilizado por empresas como Facebook e Instagram. Si tienes planeado crear soluciones UI / UX para dispositivos móviles o web, este curso es extremadamente beneficioso para mejorar tus habilidades de trabajo.</p>
                      <br/>
                      <br/>
                      <p>Después de 4 semanas tendrás tu primer proyecto en React finalizado!</p>
                    </div>

                    <div className="row justify-content-between">
                      <div className="col-lg-6">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases1} alt="Curso de React" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Primer Semana</h3>
                            <p className="mb-3 text-black-50">Introducción a React. Conceptos básicos, componentes de React</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-5 mt-lg-0">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases2} alt="curso de React js" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Segunda Semana</h3>
                            <p className="mb-3 text-black-50">React State, Props y Data Flow.</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-5">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases3} alt="Taller de React" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Tercer Semana</h3>
                            <p className="mb-3 text-black-50">React Event Handling & Life Cycle.</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-5">
                        <div className="media row">
                          <img className="col-sm-5 p-5 p-sm-0 pr-sm-3" src={Clases4} alt="curso de React" width="230"></img>
                          <div className="col-sm-7 media-body">
                            <h3 className="mb-3 t600">Cuarta Semana</h3>
                            <p className="mb-3 text-black-50">Para obtener el certificado debes presentar el Capstone, tu proyecto final!.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section nobg" >
                  <div className="container">
                    <div className="heading-block divcenter nobottomborder center" >
                      <div className="heading-block nobottomborder mb-0">
                        <small className="text-black-50 uppercase ls3 t600">Inversión: </small>
                        <h2 className="capitalize t600 mb-2">$390,000</h2>
                      </div>
                      {/* <h2 className="nott t600 mb-2" ><span className="counter counter-inherit text-dark inline-block"></span> Nuestro próximo curso inicia el<span> 8 de Febrero de 2020</span></h2> */}
                      <p className="lead">Anímate a dar este paso que cambiará tu vida.</p>
                    </div>
                  </div>
                </div>

                {/* <div id="section-price" className="section" >
                  <div className="container">
                    <h1 className="nott t600 mb-2" ><span className="counter counter-inherit text-dark inline-block">PRÓXIMOS CURSOS</span></h1>                
                    <div className="row justify-content-between">
                      <table className='date-table'> 
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Fecha de Inicio</th>
                            <th>Fecha final</th>
                            <th>Horario</th>                          
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Taller de React</td>
                            <td>08/02/120</td>
                            <td>29/02/20</td>
                            <td>Sábados 3 - 6pm</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}

                <div className="section nobg clearfix">
                  <div className="container">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-lg-6">
                        <div className="heading-block nobottomborder mb-0">
                          <small className="text-black-50 uppercase ls3 t600">Contáctanos</small>
                          <h2 className="capitalize t600 mb-2">Tienes Preguntas?!</h2>
                          <p className="lead">Nuestro equipo puede ayudarte.</p>
                        </div>
                      </div>

                      <div className="col-lg-6 d-inline-block justify-content-start justify-content-lg-end">
                        <NavLink to="/registro-curso-react" className="button button-xlarge ls0 button-rounded button-border nott t400 ">Inicia tu aplicación</NavLink>
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <SimpleFooter />
        </div>
      );
    }
  }
}

export default ReactJs;
