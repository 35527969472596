import React, {Component} from 'react';
import SimpleMenu from '../SimpleMenu/SimpleMenu';
import SimpleFooter from '../SimpleFooter/SimpleFooter';

class Websites extends Component {
  componentDidMount() {
    document.body.scrollTop = 0;
  }

  render() {
    return (
      <div>
        <SimpleMenu />
        <div className="main-container">
            <div className="main">
            {/* <Bgn/> */}
            <section className="description">
                <h1>Build an Idea</h1>
                <p>We're experts in mobile & web technologies. We create amazing, functional mobile apps and websites.</p>
                <button className='start'> Let's start</button>
            </section>
            </div>
            <div className="header">
            <div className='logos dayt'>DayTrip</div>
            <div className='logos awc'>AWC.dance</div>
            <div className='logos rosenthal'>Rosenthal</div>
            <div className='logos bpc'>Bellypassion</div>
            <div className='logos'>qBott</div>
            </div>
        </div>

        <div className="work-container panel" id='work'>
            <h1 className='work-title'>Our Latest Work</h1>
            <p class="work-subtitle">Cras egestas varius posuere. Pellentesque hendrerit tempor auctor. Sed lacinia efficitur ornare. </p>
            <section className='projects'>
                <article className='mainProject' onClick={this.props.displayProject}>
                <div className='mainProjectText'>
                    <h2>DayTrip</h2>
                    <p>Web Application ></p>
                </div>
                </article>
                <article className='project project-1' onClick={this.props.displayProject}>
                <div className='project-text'>
                    <h2>Evo-Lite</h2>
                    <p>Website / Branding / Online Marketing</p>
                    <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                </div>
                </article>
                <article className='project project-2' onClick={this.props.displayProject}>
                <div className='project-text'>
                    <h2>Viridis Design Studio</h2>
                    <p>CMS Website</p>
                    <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                </div>
                </article>
                <article className='project  project-3' onClick={this.props.displayProject}>
                <div className='project-text'>
                    <h2>AWC</h2>
                    <p>Web application</p>
                    <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                </div>
                </article>        
            </section>
        </div>

        <div className='tech-section panel' id='tech' ref='services'> 
            <h1>Services</h1>
            <div className="tech-container" >
            <div className='mobile-row'>
                <div className='mob-pic'></div>
                <div className='mob-desc'>
                <h2 className='tech-title'>Mobile Apps</h2>
                <p className='tech-p'>We're experts on building Native Mobile apps for iOS & Android and Progessive web apps.</p>
                <b>cccccc</b>
                </div>
            </div>
            <div className='website-row'>
                <div className='web-desc'>
                <h2 className='tech-title'>Websites and Web Apps</h2>
                <p className='tech-p'>From websites, Web Apps and online stores, to marketing tools and analytics. </p>
                <b>qqqqq</b>
                </div>
                <div className='web-pic'></div>
            </div>
            <div className='portfolio-row'>
                <div className='portfolio-pic'></div>
                <div className='portfolio-desc'>
                <h2 className='tech-title'>Portfolios</h2>
                <p className='tech-p'>We build stunning and responsive personal sites that will make you stand out from the competition.</p>
                <b>cccccc</b>
                </div>
            </div>
            
            </div>
        </div>
        <SimpleFooter />
      </div>
    );
  }
}

export default Websites;
