import React, {Component} from 'react';
import SimpleMenu from '../SimpleMenu/SimpleMenu';
import SimpleFooter from '../SimpleFooter/SimpleFooter';

class RegistroUX extends Component {
  componentDidMount() {
    document.body.scrollTop = 0;
  }

  render() {
    return (
      <div>
        <SimpleMenu />
        <div id="wrapper" className="section nobg mb-0">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeWJA49icv4dhMUDYtLnVF70GG_5y1-EQ5oPvp6-oIHasbK_g/viewform?embedded=true" width="95%" height="1279" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
        </div>
        <SimpleFooter />
      </div>
    );
  }
}

export default RegistroUX;
